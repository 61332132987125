/**
 * Manages the behavior of a language translation widget.
 *
 * The widget supports toggling language options on click or key press (Enter).
 * It also automatically closes when a click occurs outside the widget unless it is part of the widget's header.
 *
 * @module widgetTranslation
 * @author Dreamers of
 * Day
 */

function widgetTranslation() {
    const widget = '.js-widget-translation';
    const toggler = '.js-widget-translation-toggler';
    const headerBtn = '.js-widget-translation-header';

    $(toggler).on('click keydown', function (event) {
        // Check if the event is a click or a keydown event with the Enter key (key code 13 or key 'Enter')
        if (
            event.type === 'click' ||
            (event.type === 'keydown' &&
                (event.key === 'Enter' ||
                    event.which === 13 ||
                    event.keyCode === 13))
        ) {
            const widget_obj = $(this).closest(widget);

            if (!widget_obj) {
                return;
            }

            const languageSwitchersBlocks = widget_obj.find(
                '.js-google-translate-switcher',
            );

            const isActive = widget_obj.hasClass('active');

            // Remove or set active class on the widget
            if (isActive) {
                widget_obj.removeClass('active');
            } else {
                widget_obj.addClass('active');
            }

            // Remove or set tabindex attribute based on the widget's active state
            languageSwitchersBlocks.each(function () {
                const anchorTags = this.querySelectorAll('span');
                anchorTags.forEach((tag) => {
                    if (isActive) {
                        tag.removeAttribute('tabindex');
                    } else {
                        tag.setAttribute('tabindex', '0');
                    }
                });
            });
        }
    });

    $(headerBtn).on('click', function (e) {
        e.preventDefault();
        $(toggler).trigger('click');
    });

    $(document).on('click', function (e) {
        if (
            !$(e.target).closest(widget).length &&
            $(widget).hasClass('active') &&
            !$(e.target).closest(headerBtn).length
        ) {
            $(widget).removeClass('active');
        }
    });

    const languageSwitchersBlocks = document.querySelectorAll(
        `.js-google-translate-switcher`,
    );

    if (languageSwitchersBlocks.length) {
        // Looping through all of them (we can have several of them)
        [...languageSwitchersBlocks].map((languageSwitcherBlock) => {
            // Adding click and keydown listeners
            languageSwitcherBlock.addEventListener(
                'click',
                handleLanguageSwitch,
            );
            languageSwitcherBlock.addEventListener(
                'keydown',
                handleLanguageSwitch,
            );
        });
    }

    // Handles language switching logic for click and Enter key press on switcher elements
    function handleLanguageSwitch(event) {
        // Check if the event is a click or a keydown event with the Enter key (key code 13 or key 'Enter')
        if (
            event.type === 'click' ||
            (event.type === 'keydown' &&
                (event.key === 'Enter' ||
                    event.which === 13 ||
                    event.keyCode === 13))
        ) {
            // Click Target
            const target = event.target;
            // Language switcher
            const button = target.closest('span');
            // If we click out of button - do nothing
            if (!button) {
                return;
            }

            // Preventing default behaviour
            event.preventDefault();

            // Language value of this button
            const chosenLanguage = button.getAttribute('data-href');
            if (!chosenLanguage) {
                return;
            }

            // Google Translate Select
            const googleTranslateSelect =
                document.querySelector('.goog-te-combo');
            if (!googleTranslateSelect) {
                return;
            }

            // Set the chosen value
            googleTranslateSelect.value = chosenLanguage;
            // Dispatching event and allow google to translate the page
            googleTranslateSelect.dispatchEvent(new Event('change'));
        }
    }

    /**
     * Flag to determine if the user has scrolled.
     * @type {boolean}
     */
    let didScroll = false;

    /**
     * Stores the last scroll position.
     * @type {number}
     */
    let lastScrollTop = 0;

    /**
     * Minimum scroll distance to trigger the hiding/showing of the widget.
     * @type {number}
     */
    const delta = 5;

    /**
     * Event listener for the scroll event.
     * Sets the didScroll flag to true whenever the user scrolls.
     * @param {Event} event - The scroll event.
     */
    window.addEventListener('scroll', (event) => {
        didScroll = true;
    });

    /**
     * Interval function to check if the user has scrolled.
     * If scrolled and the widget is not active, calls the hasScrolled function.
     */
    setInterval(() => {
        if (
            didScroll &&
            !document.querySelector(widget).classList.contains('active')
        ) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    /**
     * Handles the scroll event.
     * Adds or removes the 'hide-widget' class based on the scroll direction.
     */
    function hasScrolled() {
        /**
         * Current vertical scroll position.
         * @type {number}
         */
        const scrollY = window.scrollY;

        // Ensure the user has scrolled more than the delta value.
        if (Math.abs(lastScrollTop - scrollY) <= delta) {
            return;
        }

        // Scroll down
        if (scrollY > lastScrollTop) {
            document.querySelector(widget).classList.add('hide-widget');
        } else {
            // Scroll up
            // Ensure the scroll position is not at the bottom of the page.
            if (
                scrollY + window.innerHeight <
                document.documentElement.scrollHeight
            ) {
                document.querySelector(widget).classList.remove('hide-widget');
            }
        }

        // Update the last scroll position.
        lastScrollTop = scrollY;
    }
}

export default widgetTranslation;
