/**
 * @file Plan Pages Banner Module
 * @description Handles the functionality of the plan pages banner at the bottom of the website.
 * @author Dreamers of Day
 */

/**
 * Plan Pages Banner Module
 * Toggles an active class based on scroll position.
 */
function planPagesBanner() {
    // Selector for the banner
    const selector = '.js-plan-pages-banner';

    // Active class added when scrolled past the threshold
    const activeClass = 'o-plan-pages-banner-1--active';

    // Scroll threshold for adding the active class
    const activeDistance = 200;

    /**
     * Handles scroll event to toggle the banner's visibility.
     */
    const handleScroll = () => {
        // Check if the scroll position has surpassed the active distance
        if (window.scrollY > activeDistance) {
            $(selector).addClass(activeClass);
        } else {
            $(selector).removeClass(activeClass);
        }
    };

    // Debounce the scroll event to optimize performance
    let scrollTimeout;
    $(window).on('scroll', () => {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(handleScroll, 10);
    });
}

// Export the module for external usage
export default planPagesBanner;
