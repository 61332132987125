import Swiper, { Navigation } from 'swiper';

function CardsD1() {
    Swiper.use([Navigation]);

    $('[data-module="cardsD1"]').each(function () {
        const module = $(this);

        // Initialize the mobile Swiper
        const swiper = new Swiper(module.find('[data-slider]')[0], {
            slidesPerView: 'auto',
            spaceBetween: 16,
            noSwipingSelector: 'a',
            breakpoints: {
                1024: {
                    spaceBetween: 24,
                },
            },
            navigation: {
                prevEl: module.find('.js-slider-prev')[0],
                nextEl: module.find('.js-slider-next')[0],
            },

            on: {
                init(e) {
                    // Equalize both desktop and mobile on init
                    equalizeDesktopCards();
                    equalizeMobileCards(e);
                },
                resize(e) {
                    // Re-equalize both on resize
                    equalizeDesktopCards();
                    equalizeMobileCards(e);
                },
            },
        });

        /**
         * Equalize card heights in the desktop container (__repeater).
         */
        function equalizeDesktopCards() {
            // Grab all the card wrappers in the desktop repeater
            const desktopCards = module
                .find('.m-card-image-text-a__content-wrapper')
                .filter(function () {
                    // Only those inside .__repeater (desktop) and not inside .__repeater-mobile
                    return (
                        $(this).closest('[class*="__repeater-mobile"]')
                            .length === 0
                    );
                });

            let maxHeight = 0;

            desktopCards.each(function () {
                const cardHeight = parseInt(getComputedStyle(this).height);
                if (cardHeight > maxHeight) {
                    maxHeight = cardHeight;
                }
            });

            desktopCards.each(function () {
                this.style.minHeight = `${maxHeight}px`;
            });
        }

        /**
         * Equalize card heights in the mobile Swiper slider (__repeater-mobile).
         * This receives the Swiper instance (e) so we can query `e.wrapperEl`.
         */
        function equalizeMobileCards(e) {
            // Select all card wrappers inside the Swiper’s .wrapperEl
            const sliderContents = e.wrapperEl.querySelectorAll(
                '.m-card-image-text-a__content-wrapper',
            );

            let maxHeight = 0;

            sliderContents.forEach((slide) => {
                const slideHeight = parseInt(getComputedStyle(slide).height);
                if (slideHeight > maxHeight) {
                    maxHeight = slideHeight;
                }
            });

            sliderContents.forEach((slide) => {
                slide.style.minHeight = `${maxHeight}px`;
            });
        }
    });
}

export default CardsD1;
